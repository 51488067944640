import React from 'react';
import JotFormReact from 'jotform-react';
import { graphql, useStaticQuery } from 'gatsby';

const TafcRedirect = () => {
  const data = useStaticQuery(graphql`
    query languageQuery {
      allContentfulBrands {
        edges {
          node {
            node_locale
          }
        }
      }
    }
  `);
  const details = data?.allContentfulBrands?.edges?.map((node) => {
    return node?.node.node_locale;
  });
  const isBrowser = typeof window !== 'undefined';
  const languageList = [...new Set(details)];
  const searchParam = isBrowser ? window.location.search : null;
  const params = new URLSearchParams(searchParam);
  const language = languageList.includes(params?.get('lang'))
    ? params.get('lang')
    : 'en-US';
  return (
    <>
      {language === 'en-US' ? (
        <JotFormReact formURL={`${process.env.GATSBY_JOTFORM_EN}`.toString()} />
      ) : (
        <JotFormReact formURL={`${process.env.GATSBY_JOTFORM_FR}`.toString()} />
      )}
    </>
  );
};

export default TafcRedirect;
